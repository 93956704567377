import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { CircularProgress, List, TextField } from '@material-ui/core';
import { IUser } from '@smartwood/common-client/models/user';
import { t } from '@smartwood/common-client/utils/translations';
import { cloneDeep } from 'lodash';
import React, { useCallback, useState } from 'react';
import { immutableRemove } from '../../utils/array';
import { DynamicFormFieldSpecs, DynamicFormGroup } from '../form/DynamicForm';
import { SWForm, SWFormActions, SWTextField, validators } from '../form/Form';
import { ClickableIcon } from '../generic/icons';
import { SectionTitle } from '../generic/SectionTitle';
import { userService } from '@smartwood/common-client/services/admin.service';

const saveForm = async (data: IUser, onClose) => {
  if (data.id) {
    await userService.update(data.id, data);
  } else {
    await userService.create(data);
  }
  onClose();
};

export const UserForm = ({ entry, fromCurrentUser, onClose }: UserFormProps) => {
  const [data, setData] = useState<Partial<IUser> | undefined>(cloneDeep(entry));
  const onSubmit = useCallback((d) => {
    if (d.roles === {}) {
      d.roles = null;
    }
    saveForm(d, onClose);
  }, [onClose]);

  if (!data) return <CircularProgress />;
  return <>
    <SectionTitle title={t('admin.users.' + (data.name ? 'edit' : 'new'))} />
    <SWForm model={data} setModel={setData} onSubmit={onSubmit} >
      <DynamicFormGroup
        translationKey='admin.users'
        specs={UserSpec}
      />
      <SWTextField
        label={t('admin.users.email')}
        field='email'
        validations={[validators.required]}
        disabled={fromCurrentUser}
      />
      <SectionTitle title={t('admin.users.roles.title')} />

      <List>
        {renderRolesList(data, setData, true)}
      </List>
      <SWFormActions onCancel={onClose} isCreate={!data.name} />
    </SWForm>
  </>;
};
// <StyledUserRolesSelector
//   label={t('admin.users.roles.addRole')}
//   onSelect={u => addNewRole(data, u, setData)}
//   excludedRoles={data?.roles?.map(u => u.role)}
//   disabled={fromCurrentUser}
// />
const validateAfter = (field: string) => (v, model: Partial<IUser>) =>
  (!v && !model[field]) &&
  t('validations.phone_required');

const UserSpec: DynamicFormFieldSpecs = {
  name: { type: 'string', validators: [validators.maxLength(30), validators.required] },
  surname: { type: 'string', validators: [validators.maxLength(30), validators.required] },
  phone: {
    type: 'string',
    validators: [validators.maxLength(9), validateAfter('mobile_phone')],
    validateWith: ['mobile_phone']
  },
  mobile_phone: {
    type: 'string',
    validators: [validators.maxLength(9), validateAfter('phone')],
    validateWith: ['phone']
  }
};

// const addNewRole = (data, role, setData) => {
//   const roles = data.roles || [];
//   setData({ ...data, roles: [...roles, { element_id: null, role }] });
// };

const renderRolesList = (data, setData, fromCurrentUser) => {
  const list = data?.roles || [];
  return list.map((Role) => <li>
    <TextField
      disabled={fromCurrentUser}
      label=''
      value={t('admin.users.roles.' + Role.role)}
    />
    {!fromCurrentUser &&
      <ClickableIcon
        icon={faTrash}
        onClick={() => setData({ ...data, roles: immutableRemove(list, Role) })}
      />}
  </li>
  );
};

// interface UserRolesSelectorProps {
//   label: string;
//   onSelect: (string) => void;
//   className?: string;
//   excludedRoles?: string[];
//   disabled?: boolean;
//   filter?: (string) => boolean;
// }

interface UserFormProps {
  entry?: Partial<IUser>;
  fromCurrentUser?: boolean;
  onClose();
}