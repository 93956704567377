"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatDate = exports.mapDates = exports.API_DATE_FORMAT = void 0;
const moment = require("moment");
exports.API_DATE_FORMAT = 'YYYY-MM-DD';
exports.mapDates = (data) => {
    if (data === null || typeof data !== 'object')
        return data;
    let result = {};
    Object.entries(data).forEach(([k, v]) => {
        if (Array.isArray(data))
            result = data.map(exports.mapDates);
        else if (v instanceof Date)
            result[k] = moment(v).format(exports.API_DATE_FORMAT);
        else if (moment.isMoment(v))
            result[k] = v.format(exports.API_DATE_FORMAT);
        else if (typeof v === 'object')
            result[k] = exports.mapDates(v);
        else
            result[k] = v;
    });
    return result;
};
exports.formatDate = (d) => (d == null || typeof d === 'string') ? d : moment(d).format(exports.API_DATE_FORMAT);
